import { v4 as uuidv4 } from "uuid";

export const ExpiryDiscount = async (product, pricingRule, setCart, cart, orderType, cartObj, dateDifference, enteredDate) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let excludingFlag = true;

  const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);

  if (
    (pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||
    (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||
    (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);

  if (
    (pricingRule.excludeBrands === "Y" && matchingBrand) ||
    (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
    (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
  ) {
    excludingFlag = false;
  }

  if (pricingRule.mPricingBUnits.length > 0) {
    excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
  }

  // if (pricingRule.mPricingXProducts.length > 0) {
  //   const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);

  //   if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
  //     excludingFlag = false;
  //   }
  // }

  let pricingRuleFlag = true;
  // if(pricingRule.mPricingXProducts.length>0){
  //   pricingRule.mPricingXProducts.some((op) => {
  //     cartObj.items.map((product)=> {
  //       if(op.mProductId === product.productId && product.nextRule === "Y"){
  //         pricingRuleFlag = false;
  //       }else if(op.mProductId === product.productId && !product.nextRule){
  //         pricingRuleFlag = true;
  //       }
  //     })
  //   })
  // }else{
  //   if(product.nextRule === "Y"){
  //     pricingRuleFlag = false;
  //   }
  // }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
    const addedToCart = cartObj.items;
    const index = addedToCart.findIndex((p) => p.productId === product.productId && p.upc === product.upc && p.mBatchId === product.mBatchId);
    // const offerIndex = pricingRule.mPricingExpiryDiscount.length > 0 ? pricingRule.mPricingExpiryDiscount.findIndex((op) => op.mProductId === product.productId) : 0;
    const productAvailabilityIndex = pricingRule.mPricingExpiryDiscount.findIndex((item) => item.mProductId === product.productId);
    let offerIndex;

    if (productAvailabilityIndex !== -1) {
      offerIndex = productAvailabilityIndex;
    } else {
      offerIndex = pricingRule.mPricingExpiryDiscount.length > 0 ? pricingRule.mPricingExpiryDiscount.findIndex((op) => op.mProductId === null) : -1;
    }

    if (offerIndex >= 0 && index >= 0) {
      if (addedToCart[index].weight >= pricingRule.minimumQty) {
        const findDiscountPercentage = (number) => {
          // First pass: Check for exact product matches
          for (const range of pricingRule.mPricingExpiryDiscount) {
            if (number >= parseInt(range.rangeFrom) && number <= parseInt(range.rangeTo) && range.mProductId === product.productId) {
              return range.discountPercentage;
            }
          }

          // Second pass: Check for ranges with null mProductId
          for (const range of pricingRule.mPricingExpiryDiscount) {
            if (number >= parseInt(range.rangeFrom) && number <= parseInt(range.rangeTo) && range.mProductId === null) {
              return range.discountPercentage;
            }
          }

          // If no match is found, return 0
          return 0;
        };

        let discountPer = findDiscountPercentage(dateDifference);
        const discountAmt = (discountPer / 100) * parseFloat(addedToCart[index].realPrice);
        const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
        const mrp = parseFloat(sp) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].salePrice = sp;
        addedToCart[index].taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        addedToCart[index].nettotal = parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        addedToCart[index].discount = parseFloat((discountAmt * addedToCart[index].weight).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        addedToCart[index].discountName = pricingRule.printedName;
        addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
        addedToCart[index].nextRule = pricingRule.nextRule;
        addedToCart[index].expiryId = uuidv4().replace(/-/g, "").toUpperCase();
        addedToCart[index].lineId = uuidv4().replace(/-/g, "").toUpperCase();
        addedToCart[index].expiryDate = enteredDate;
        // message.success(pricingRule.printedName);
      } else {
        const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        addedToCart[index].nettotal = parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        addedToCart[index].discount = 0;
        addedToCart[index].discountName = "";
        addedToCart[index].mPricingruleId = null;
        addedToCart[index].lineId = uuidv4().replace(/-/g, "").toUpperCase();
        delete addedToCart[index].expiryDate;
        delete addedToCart[index].nextRule;
      }

      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
      for (let i = 0; i < addedToCart.length; i += 1) {
        totalPrice += addedToCart[i].nettotal;
        totalItemsQty += addedToCart[i].weight;
        totalTax += addedToCart[i].taxAmount;
        totalDiscounts += addedToCart[i].discount;
        addedToCart[i].key = i;
      }
      const calculateRoundingDifference = (value) => {
        // Round up the payment to the nearest multiple of 0.25
        const roundedPayment = Math.ceil(value / 0.25) * 0.25;

        // Calculate the raw overpayment (before considering change)
        const rawOverpayment = roundedPayment - Math.abs(totalPrice);
        return parseFloat(rawOverpayment.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
      };

      const updatedTotalRoundOff = calculateRoundingDifference(totalPrice);
      setTimeout(() => {
        let newObj = {
          ...cartObj,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: updatedTotalRoundOff,
        };
        setCart(newObj);
        localStorage.setItem("cartObj", JSON.stringify(newObj));
      }, 500);
    }
  }
};
