import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import RetailModal from "../Retail/RetailModals";
import db from "../../../database";
import Calender from "../../../assets/images/calender.svg";

const CashManagement = (props) => {
  const { Text, Title } = Typography;
  const { setAddCashFlag, setCashAddInFlag } = props;
  const currentDateTime = useState(moment(new Date()).format("ddd, D MMM YYYY"));
  const [cashInData, setCashInData] = useState([]);

  useEffect(() => {
    db.cashInCashOut.toArray().then((res) => {
      setCashInData(res);
    });
  }, []);

  return (
    <div style={{ padding: "2vh 1.5vw", position: "relative", height: "100vh"}}>
      <RetailModal {...props} />
      <Row>
        <Col span={6} offset={18} style={{ textAlign: "right",fontSize:"1vw"  }}>
        <img src={Calender} style={{height:"3.5vh",paddingRight:"0.5vw",paddingBottom:"0.3em"}}/>
          <span style={{fontSize:"1.25em",color:"#929098",fontWeight:"500"}}>{currentDateTime}</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {cashInData.map((recordData) => {
          return (
            <Col span={8}>
              <Card style={{height:"14vh",width:"100%",borderRadius:"10px",padding:"2vh 1.3vw",fontSize:"1vw"}}>
                <Space style={{ width: "100%", justifyContent: "space-between" }}>
                  <Text style={{fontSize:"1.25em",fontWeight:"600",color:"#0F0718"}}>{recordData.note}</Text>
                  <Text style={{fontSize:"1.25em",fontWeight:"600",color:"#0F0718"}}>{recordData.type}</Text>
                </Space>
                <Space style={{width: "100%", justifyContent: "space-between", paddingTop: "2vh"}}>
                  <Text style={{fontSize:"1em",fontWeight:"500",color:"#0F0718"}}>{recordData.date}</Text>
                  <Text style={{fontSize:"1.25em",fontWeight:"600",color:"#0F0718"}}>{recordData.amount}</Text>
                </Space>
              </Card>
            </Col>
          );
        })}
      </Row>
      <div>
        <Space direction="horizontal" style={{ position: "absolute", right: "1.3vw", bottom: "2vh",fontSize:"1vw" }}>
          <Button
            onClick={() => {
              setAddCashFlag(true);
              setCashAddInFlag(true);
            }}
            style={{height:"6vh",backgroundColor:"#2F3856",color:"#fff",width:"11vw",borderRadius:"10px",fontSize:"1em",fontWeight:"500"}}
           >
            Remove Cash
          </Button>
          <Button
            onClick={() => {
              setAddCashFlag(true);
              setCashAddInFlag(false);
            }}
            style={{height:"6vh",backgroundColor: "#2F3856", color: "#fff", width: "11vw", borderRadius: "10px",fontSize:"1em",fontWeight:"500"  }}
           >
            Add Cash
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default CashManagement;
